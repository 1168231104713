<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-icon left>mdi-alpha-a-circle</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Assets ({{ totalAssets }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'maintenance.asset.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Asset
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="maintenance-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name, location and building"
          @input="search"
        ></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="assets"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetch"
        :server-items-length="totalAssets"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.building="{ item }">
          <div>
            {{ item.buildingName }}
          </div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div>
            {{ item.updatedDate }}
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>
            {{ item.addedDate }}
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Maintenance Request list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'

export default {
  name: 'AssetsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {},

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'created_at',
      sortOrder: false,
      selected: [],
      headers: [
        {
          text: 'Asset Name',
          value: 'name',
        },
        {
          text: 'Asset Location',
          value: 'location',
        },
        {
          text: 'Building',
          value: 'building',
        },
        {
          text: 'Updated date',
          value: 'updated_at',
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
      ],
      actions: [],
      options: {},
      pageTables: 0,
      itemsPerPageTables: 0,
    }
  },

  created() {
    this.fetchInitParams()
    this.clearAssets()
    this.fetch(this.options)
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      assets: (state) => state.asset.list,
      listMeta: (state) => state.asset.listMeta,
      totalAssets: (state) => state.asset.totalAssets,
      params: (state) => state.asset.params,
      scrollPosition: (state) => state.asset.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getAssets: 'asset/getAssets',
    }),

    ...mapMutations({
      clearAssets: 'asset/clearAssetList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'asset/setParams',
      setScrollPosition: 'asset/setScrollPosition',
    }),

    filterList(filterValue) {
      this.clearAssets()
      this.fetch(this.options, filterValue)
    },

    activeAction() {
      this.clearAssets()
      this.fetch(this.options)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.options, this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearAssets()
      this.fetch(this.options)
    }, 600),

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        options: {
          page: options?.page || 1,
          itemsPerPage: options?.itemsPerPage
            ? options?.itemsPerPage === -1
              ? this.totalTags
              : options.itemsPerPage
            : 25,
          sort: options?.sortBy[0]
            ? options?.sortDesc[0]
              ? '-' + options.sortBy[0]
              : options.sortBy[0]
            : '',
        },
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.setParams(params)

      this.loading = true
      await this.getAssets(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },
    editItem(item) {
      this.$router.push({
        name: 'maintenance.asset.details',
        params: { id: item.id },
      })
    },

    fetchInitParams() {
      this.options = this.params.options

      this.filter.search = this.params.search
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return

      const scrollPosition = this.scrollPosition
      this.setScrollPosition(0)

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
</style>
